.navbar{
    display: flex;
    padding: 1em;
}

.logo{
    flex-basis: 40%;
}

.nav_bar_links{
    flex-basis: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

@media screen and (width<900px){
    .logo{
        flex-basis: 0%;
    }
    
    .nav_bar_links{
        flex-basis: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    
    }
  }