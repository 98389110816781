.course_cards{
    padding: 1em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2em;

}

.card__button{
    display: flex;
    justify-content: space-around;
}

.course_card
{
    flex-basis: 22%;
}
.explore_button{
    background-color: aqua;
}

@media screen and (max-width:700px) {
    .course_cards .course_card{
        flex-basis: 100%;
    }
    .diiv{
        justify-content: center !important;
    }

    .css-1ri6ub7-MuiPaper-root-MuiCard-root{
        max-width: 420px !important;
        margin: auto;
    }
}

@media screen and (max-width:950px) {
    .course_card{
        flex-basis: 30%;
        
    }

    .course_cards{
        gap: 1.5em;
        justify-content: flex-start;
    }
}