.topic_main_container {
  width: 100%;
  margin-top: 1em;
  padding: 0.5em;
}

.topic_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em 0.5em 2em;
  border-radius: 10px 10px ;
  background-color: hsla(0, 100%, 100%, 0.09);	
	box-shadow:
		0px 4px 5px 0px hsla(0,0%,0%,0.14), 
		0px 1px 10px 0px hsla(0,0%,0%,0.12), 
		0px 2px 4px -1px hsla(0,0%,0%,0.2);
}

.topic_button {
  margin-right: 2em;
}

.topic_button button {
  background-color: #36a9ae;
  border: 1px solid #2a8387;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 1px;
  color: #ffffff;
  cursor: pointer;
  font-size: 17px;
  line-height: 100%;
  margin: 0;
  outline: 0;
  padding: 11px 15px 12px;
  text-align: center;
  transition: box-shadow 0.05s ease-in-out, opacity 0.05s ease-in-out;
  width: 100%;
}

.topic_button button:hover {
  box-shadow: rgba(255, 255, 255, 0.3) 0 0 2px inset,
    rgba(0, 0, 0, 0.4) 0 1px 2px;
  text-decoration: none;
  transition-duration: 0.15s, 0.15s;
}

.topic_button button:active {
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 4px inset, rgba(0, 0, 0, 0.4) 0 1px 1px;
}

.topic_button button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.topic_button button:disabled:active {
  pointer-events: none;
}

.topic_button button:disabled:hover {
  box-shadow: none;
}

@media screen and (width<600px){
  .topic_main_container{
    width: 100%;
  }
  .topic_card{
    flex-direction: column!important;
    gap: 1em;
  }
  .progress_main_container h1{
    width: 100%;
  }
}
